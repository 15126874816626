import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../../../../store/modal';
import { addAsset, getWalletAssets } from '../../../../store/wallets';
import { getWalletById, getBalances } from '../../../../store/wallets';
import { icon } from '../../../../config/tailwind.classnames';
import { NetworkLogo } from '../../../../assets/networks';
import { nativeTokenByNetwork } from '../../../../utils/networkTools';
import { getAvailableAssets } from '../../../../utils/assets';

const AddAsset = ({ id }) => {
  const [availableAssets, setAvailableAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [activeHover, setActiveHover] = useState(null);

  const wallet = useSelector(state => state?.wallets && state.wallets[id]);
  const wallets = useSelector(state => state.wallets);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!wallets.assets) {
      return;
    }
    const controller = new AbortController();
    dispatch(getWalletById(controller.signal, id))
      .then(res => {
        res &&
          res.accounts &&
          setAvailableAssets(
            getAvailableAssets(wallets.assets, res.accounts)
          );
      })
      .catch(e => console.log(e));
    return () => controller.abort();
  }, [dispatch, id, !!wallets.assets]);

  useEffect(() => {
    dispatch(
      getWalletAssets({})
    )
      .catch(e => {
        console.log(e)
        toast.error(`Error getting wallet assets: ${e}`);
      });
  }, [])

  useEffect(() => {
    if (!wallet.active) {
      toast.dismiss();
      toast.error('Wallet has been disabled. Contact an administrator.');
      dispatch(hideModal());
    }
  }, [wallet, dispatch]);

  const selectedClass =
    'text-center p-5 bg-blue-800 hover:bg-blue-600 cursor-pointer rounded relative mr-5';
  const unselectedClass =
    'text-center p-5 bg-gray-700 hover:bg-slate-600 cursor-pointer rounded relative mr-5';

  const assetHover = n => setActiveHover(n);

  const handleSubmit = async e => {
    e.preventDefault();
    if (!selectedAsset) {
      toast.error('Please choose a network to add.');
    } else {
      toast.loading('Submitting request...');
      dispatch(addAsset({ walletId: id, network: selectedAsset.network, asset: selectedAsset.asset }))
        .then(async () => {
          const controller = new AbortController();
          await dispatch(getBalances(controller.signal, { walletId: id }));
        })
        .then(_ => {
          toast.dismiss();
          toast.success('Asset added successfully!');
        })
        .then(() => dispatch(hideModal()))
        .catch(err => toast.error(err?.message));
    }
  };

  return (
    <div className="text-white m-5">
      {wallet && (
        <div className="bg-gray-900 bg-opacity-90 rounded-lg p-10 min-w-[300px]">
          <div className="text-right text-sm">
            <span className="text-gray-400 mr-2">Wallet ID:</span>
            <span>{id}</span>
          </div>
          <div className="text-right text-sm">
            <span className="text-gray-400 mr-2">Owner:</span>
            <span>{wallet.owner}</span>
          </div>
          <form className="mt-5" onSubmit={handleSubmit}>
            <div className="text-2xl">Which asset would you like to add?</div>
            <div className="flex my-16">
              {availableAssets.map(assetData => (
                <div
                  key={assetData.asset}
                  className={selectedAsset?.asset === assetData.asset ? selectedClass : unselectedClass}
                  onClick={() => setSelectedAsset(assetData)}
                  onMouseEnter={() => assetHover(assetData.asset)}
                  onMouseLeave={() => assetHover(null)}
                >
                  <div className="w-12">
                    <NetworkLogo network={nativeTokenByNetwork(assetData.network)} token={assetData.asset} size="s" />
                  </div>
                  <div
                    className={`absolute flex justify-center items-center bg-slate-900 bg-opacity-50 top-0 left-0 right-0 bottom-0 rounded ${
                      activeHover !== assetData.asset && 'hidden'
                    }`}
                  >
                    {assetData.asset.toUpperCase()}
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full mt-8 flex justify-evenly">
              <button
                className={`${icon.green} mr-3 hover:bg-gradient-to-br hover:from-green-400 hover:to-green-500/75`}
                type="submit"
                disabled={!selectedAsset}
              >
                Add Asset
              </button>
              <button
                className={`${icon.grey} !text-white ml-3`}
                onClick={() => dispatch(hideModal())}
                type="button"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddAsset;
