import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSort from '../../../hooks/useSort';
import usePaginate from '../../../hooks/usePaginate';
import PageNavigation from '../../../utils/PageNavigation';
import { Loading } from '../../../assets/icons/loading';
import { baseCard } from '../../../config/tailwind.classnames';
import axiosRequest from '../../../store/axios';
import { getWalletsAsSuperAdmin } from '../../../store/wallets';
import { urls } from '../../../config';
import DepositHeader from './DepositHeader';
import DepositListItem from './DepositListItem';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import Select from 'react-select';
import { base } from '../../../config/select.styles';
import FilterEntries from './actions/FilterEntries';

/**
 * @description - Get transactions by calling the API. Just return the data, or throw if the call errors for some reason.
 * @throws
 * @param {*} user - the user (from the redux cache)
 * @param {String} role - the user's preferred role
 * @returns {Promise<*[]>} - a slice of transactions
 */

const assetMapping = {
  bitcoin: 'btc',
  ethereum: 'eth',
  avalanche: 'avax',
  polymesh: 'polyx',
  stellar: 'xlm',
};

async function getDeposits(user, role) {
  let url;
  switch (role) {
    case 'superadmins':
    case 'admins':
      url = `${urls.adminUrl}/deposits`;
      break;
    default:
      url = `${urls.apiUrl}/deposits`;
  }
  const controller = new AbortController();
  const opts = { signal: controller.signal };
  const res = await axiosRequest(url, opts);
  if (!res || !res.status) throw new Error('No response');
  if (res.status >= 300) throw new Error(`Error status ${res.status}`);
  return res.data;
}

function DepositList() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeSort, setActiveSort] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchCategory, setSearchCategory] = useState({ label: 'Wallet ID', key: 'walletId' });
  const [deposits, setDeposits] = useState([]);
  const [filteredDeposits, setFilteredDeposits] = useState([]);

  // Redux
  const dispatch = useDispatch();
  const wallets = useSelector(state => state.wallets);
  const { user, role } = useSelector(state => state.session);

  // Sort and paginate transactions. Allow jumping to pages by page number.
  const { sortedItems, requestSort, sortConfig } = useSort(filteredDeposits);
  const { prev, jump, next, page } = usePaginate(sortedItems, 20);
  const [displayPageNumber, setDisplayPageNumber] = useState(page.current);

  const [filter, setFilter] = useState({
    networks: {
      bitcoin: false,
      ethereum: false,
      avalanche: false,
      polymesh: false,
      stellar: false,
    },
    seenAt: ''
  });
  const [organizationVisibility, setOrganizationVisibility] = useState({
    ETANATRUST: true,
    BDACS: true,
  });

  useEffect(() => jump(displayPageNumber), [displayPageNumber, jump]);

  // Get deposits from API, which just queries the database
  useEffect(() => {
    getDeposits(user, role)
      .then(d => {
        setDeposits(d || []);
        setFilteredDeposits(d || []);
      })
      .catch(() => setApiError(true))
      .finally(() => setIsLoaded(true));
  }, [user, role]);

  // Update wallets (important for filter below)
  useEffect(() => {
    if (role !== 'superadmins') return;
    const controller = new AbortController();
    dispatch(getWalletsAsSuperAdmin(controller.signal));
  }, [dispatch, role]);

  // Filter by network, approval status, etc
  useEffect(() => {
    const filteredData = Object.values(deposits)?.filter(item => {
      if (role === 'superadmins' && wallets) {
        const wallet = wallets[item.walletId];
        if (!wallet || !organizationVisibility[wallet.orgId]) return false;
      }
      if (searchCategory.key === 'walletId') {
        if (!item.walletId.includes(searchQuery)) {
          return false;
        }
      } else if (searchCategory.key === 'origin') {
        const originString =
          item.originAddress ||
          (item.originAddresses && item.originAddresses.length > 1
            ? `${item.originAddresses[0]} and ${item.originAddresses.length - 1} more`
            : item.originAddresses[0]);
        if (!originString || !originString.includes(searchQuery)) {
          return false;
        }
      }
      const currentDate = new Date();
      const itemDate = new Date(item.seenAt);
      let isDateInRange = false;
      switch (filter.seenAt) {
        case '24h':
          isDateInRange = currentDate - itemDate <= 24 * 60 * 60 * 1000;
          break;
        case '7d':
          isDateInRange = currentDate - itemDate <= 7 * 24 * 60 * 60 * 1000;
          break;
        case '30d':
          isDateInRange = currentDate - itemDate <= 30 * 24 * 60 * 60 * 1000;
          break;
        case 'all':
        default:
          isDateInRange = true;
          break;
      }
      if (!isDateInRange) {
        return false;
      }

      const filterKey = Object.keys(assetMapping).find(key => assetMapping[key] === item.asset);
      if (
        filterKey &&
        !filter.networks[filterKey] &&
        !Object.values(filter.networks).some(v => v)
      ) {
        return true;
      } else if (filterKey && !filter.networks[filterKey]) {
        return false;
      }

      return true;
    });
    setFilteredDeposits(filteredData);
  }, [deposits, wallets, organizationVisibility, role, searchQuery, searchCategory, filter]);
  const getClassNamesFor = name => {
    if (!sortConfig) return;
    return sortConfig.key === name ? sortConfig.direction : '';
  };

  const sortBy = key => setActiveSort(`${key}--${requestSort(key)}`);

  const showCaret = name => {
    if (!activeSort) return;
    const [key, direction] = activeSort.split('--');
    if (name !== key) return;

    if (direction === 'ascending') return <ChevronUpIcon className="w-3" />;
    if (direction === 'descending') return <ChevronDownIcon className="w-3" />;
  };

  const pageNavigation = (
    <PageNavigation
      prev={prev}
      jump={jump}
      next={next}
      curr={page.current}
      max={page.max}
      displayPageNumber={displayPageNumber}
      setDisplayPageNumber={setDisplayPageNumber}
    />
  );
  const handleSearchInputChange = e => {
    setSearchQuery(e.target.value);
  };

  const handleSelect = e => {
    setSearchQuery('');
    setSearchCategory(e);
  };

  return (
    <div className="xl:px-28">
      <DepositHeader
        props={{
          deposits,
          organizationVisibility,
          setOrganizationVisibility,
          filter,
          setFilter,
          jump,
        }}
      />
      {/* Search */}
      <div className="flex py-3">
        <span className="min-w-[80px] flex items-center">Search by</span>
        <div className="pr-3">
          <Select
            className="w-36 hover:bg-gray-600 ml-4"
            defaultValue={{ label: 'Wallet ID', name: 'Wallet ID' }}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={[
              { label: 'Wallet ID', key: 'walletId' },
              { label: 'Origin', key: 'origin' },
            ]}
            placeholder="select prefix"
            onChange={handleSelect}
            styles={base}
          />
        </div>
        <input
          className="w-full bg-transparent border-b p-1 focus:outline-none"
          placeholder={searchCategory.label}
          onChange={handleSearchInputChange}
          value={searchQuery}
        />
      </div>

      <div className={`${baseCard} ${role === 'admins' && 'border-2 !border-green-500'}`}>
      <div className=" space-x-2" align="right">
          <FilterEntries filter={filter} setFilter={setFilter} jump={jump} />
        </div>
        {page.data().length ? (
          <>
            {pageNavigation}
            <table className="w-full">
              <thead>
                <tr className="h-8 w-full text-xs text-left leading-none text-white font-semibold px-4">
                  <th></th>
                  <th>
                    <button
                      onClick={() => sortBy('walletId')}
                      className={`${getClassNamesFor('walletId')} flex items-center`}
                    >
                      <span className="pl-4">Wallet ID</span>
                      <span className="pl-2">{showCaret('walletId')}</span>
                    </button>
                  </th>
                  <th>
                    <button
                      onClick={() => sortBy('amount')}
                      className={`${getClassNamesFor('amount')} flex items-center`}
                    >
                      <span className="pl-4">Amount</span>
                      <span className="pl-2">{showCaret('amount')}</span>
                    </button>
                  </th>
                  <th>
                    <button
                      onClick={() => sortBy('seenAt')}
                      className={`${getClassNamesFor('seenAt')} flex items-center`}
                    >
                      <span className="pl-4">Date Seen</span>
                      <span className="pl-2">{showCaret('seenAt')}</span>
                    </button>
                  </th>
                  <th>
                    <span className="pl-4">Origin</span>
                  </th>
                </tr>
              </thead>
              <tbody className="w-full">
                {page.data().map(d => (
                  <DepositListItem key={d.txHash} data={d} searchQuery={searchQuery} />
                ))}
              </tbody>
            </table>
            <div className="flex w-full items-center text-white text-sm justify-center py-4">
              {page.from} - {page.to} of {sortedItems.length} deposits
            </div>
            {pageNavigation}
          </>
        ) : isLoaded && apiError ? (
          <div className="flex flex-col w-full items-center text-white text-sm justify-center py-4 pt-8">
            Error retrieving deposits.
            <button
              className="inline-flex items-start justify-start px-6 py-4 bg-slate-700 hover:bg-slate-600 focus:outline-none rounded text-white mt-5"
              onClick={() => window.location.reload()}
            >
              Try Again
            </button>
          </div>
        ) : isLoaded ? (
          <div className="flex w-full items-center text-white text-sm justify-center py-4 pt-8">
            No deposits
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}

export default DepositList;
