import { useEffect, useState, useRef, ChangeEvent } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import useClickOutside from '../../../../hooks/useClickOutside';

export type FilterEntriesProps = {
  filter: any;
  setFilter: (filter: any | ((curr: any) => void)) => void;
  jump: (page: number) => void;
};

export const FilterEntries = (props: FilterEntriesProps) => {
  const { filter, setFilter, jump } = props;
  const [full, setFull] = useState(true);
  const [empty, setEmpty] = useState(false);

  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const filterWrapperRef = useRef(null);

  useEffect(() => {
    setFull(isDefault());
    setEmpty(isEmpty());
    jump(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const defaultFilter = {
    networks: {
      bitcoin: true,
      ethereum: true,
      avalanche: true,
      polymesh: true,
      stellar: true,
    },
    approvalStatus: {
      approved: true,
      pending: true,
      rejected: true,
    },
  };

  const showNothing = {
    networks: {
      bitcoin: false,
      ethereum: false,
      avalanche: false,
      polymesh: false,
      stellar: false
    },
    approvalStatus: {
      approved: false,
      pending: false,
      rejected: false,
    },
  };

  const isDefault = () => {
    const { bitcoin: btc, ethereum: eth, avalanche: av, polymesh: px, stellar: xlm} = filter.networks;
    const { approved: a, pending: p, rejected: r } = filter.approvalStatus;
    return btc && eth && av && px && xlm && a && p && r;
  };

  const isEmpty = () => {
    const { bitcoin: btc, ethereum: eth, avalanche: av, polymesh: px, stellar: xlm } = filter.networks;
    const { approved: a, pending: p, rejected: r } = filter.approvalStatus;
    return !btc && !eth && !av && !px && !xlm && !a && !p && !r;
  };

  const resetFilter = () => {
    if (full) return;
    setFilter({
      ...defaultFilter,
      networks: { ...defaultFilter.networks },
      approvalStatus: { ...defaultFilter.approvalStatus },
    });
  };

  const clearAll = () => {
    if (empty) return;
    setFilter({
      ...showNothing,
      networks: { ...showNothing.networks },
      approvalStatus: { ...showNothing.approvalStatus },
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const networks = ['bitcoin', 'ethereum', 'avalanche', 'polymesh', 'stellar'];
    const statuses = ['approved', 'pending', 'rejected'];

    const newState = {
      ...filter,
      networks: { ...filter.networks },
      approvalStatus: { ...filter.approvalStatus },
    };

    if (networks.includes(e.target.name)) {
      newState.networks[e.target.name] = !newState.networks[e.target.name];
    }

    if (statuses.includes(e.target.name)) {
      newState.approvalStatus[e.target.name] = !newState.approvalStatus[e.target.name];
    }

    setFilter({
      ...newState,
      networks: { ...newState.networks },
      approvalStatus: { ...newState.approvalStatus },
    });
  };

  const handleOpenMenu = () => {
    setFilterMenuOpen(true);
  };
  
  const handleCloseMenu = () => {
      setFilterMenuOpen(false)
  };

  useClickOutside(filterWrapperRef , handleCloseMenu);

  const selectors = 'w-full rounded text-white text-sm h-8 mr-2';
  const labelText = 'flex text-white items-center text-sm';

  return (
    <div
      className="relative self-center text-white group h-fit"
      ref={filterWrapperRef}
    >
      <button
        onClick={filterMenuOpen ? handleCloseMenu : handleOpenMenu}
        className={`${
          filterMenuOpen ? 'bg-gray-700 rounded-t' : 'rounded'
        } group-hover:text-green-600 flex items-center text-sm`}
      >
        <span>
          <FunnelIcon className="w-4" />
        </span>
        <span className="pl-2">Filter</span>
      </button>
      {filterMenuOpen ? (
        <div className="absolute right-0 z-30 p-5 transition-opacity duration-500 rounded-b rounded-l shadow-2xl group bg-gradient-to-r from-slate-500 to-slate-700 w-128">
          <div className="w-full mt-2 mb-2">
            <div className="flex flex-col w-full">
              <div className="mb-2 text-sm font-bold text-left">Networks</div>
              <div className="flex justify-between w-full">
                <label className={labelText}>
                  <input
                    type="checkbox"
                    className={selectors}
                    name="bitcoin"
                    onChange={handleChange}
                    checked={filter.networks.bitcoin}
                  />
                  Bitcoin
                </label>
                <label className={labelText}>
                  <input
                    type="checkbox"
                    className={selectors}
                    name="ethereum"
                    onChange={handleChange}
                    checked={filter.networks.ethereum}
                  />
                  Ethereum
                </label>
                <label className={labelText}>
                  <input
                    type="checkbox"
                    className={selectors}
                    name="avalanche"
                    onChange={handleChange}
                    checked={filter.networks.avalanche}
                  />
                  Avalanche
                </label>
                <label className={labelText}>
                  <input
                    type="checkbox"
                    className={selectors}
                    name="polymesh"
                    onChange={handleChange}
                    checked={filter.networks.polymesh}
                  />
                  Polymesh
                </label>
                <label className={labelText}>
                  <input
                    type="checkbox"
                    className={selectors}
                    name="stellar"
                    onChange={handleChange}
                    checked={filter.networks.stellar}
                  />
                  Stellar
                </label>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="mt-5 mb-2 text-sm font-bold text-left">Approval Status</div>
              <div className="flex justify-between w-full">
                <label className={labelText}>
                  <input
                    type="checkbox"
                    className={selectors}
                    name="approved"
                    onChange={handleChange}
                    checked={filter.approvalStatus.approved}
                  />
                  Approved
                </label>
                <label className={labelText}>
                  <input
                    type="checkbox"
                    className={selectors}
                    name="pending"
                    onChange={handleChange}
                    checked={filter.approvalStatus.pending}
                  />
                  Pending
                </label>
                <label className={labelText}>
                  <input
                    type="checkbox"
                    className={selectors}
                    name="rejected"
                    onChange={handleChange}
                    checked={filter.approvalStatus.rejected}
                  />
                  Rejected
                </label>
              </div>
            </div>
            <div className="flex justify-end pt-5">
              <div
                className={
                  !empty
                    ? 'text-white text-sm cursor-pointer hover:text-green-600 w-fit ml-5'
                    : 'text-gray-500 text-sm w-fit cursor-not-allowed select-none ml-5'
                }
                onClick={clearAll}
              >
                Clear All
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default FilterEntries;
