import { validate } from 'bitcoin-address-validation';
import { isAddress } from '@ethersproject/address';
import { base58_to_binary } from 'base58-js';
import { JSX } from 'react';

import eth from '../assets/networks/color/eth.svg';
import avax from '../assets/networks/color/avax.svg';
import btc from '../assets/networks/color/btc.svg';
import polyx from '../assets/networks/color/polymesh.svg';
import xlm from '../assets/networks/color/xlm.svg';

import eth_bw from '../assets/networks/monochrome/eth.svg';
import avax_bw from '../assets/networks/monochrome/avax.svg';
import btc_bw from '../assets/networks/monochrome/btc.svg';
import polyx_bw from '../assets/networks/monochrome/polymesh.svg';
import xlm_bw from '../assets/networks/monochrome/xlm.svg';

import { BigNumber } from 'bignumber.js';

export function TokenImage(network: string, fmt: 'mono' | undefined): JSX.Element {
  switch (network) {
    case 'ethereum':
      if (fmt === 'mono') {
        return <img src={eth_bw} alt="Ethereum logo" title="Ethereum" />;
      }
      return <img src={eth} alt="Ethereum logo" title="Ethereum" />;
    case 'avalanche':
      if (fmt === 'mono') {
        return <img src={avax_bw} alt="Avalanche logo" title="Avalanche" />;
      }
      return <img src={avax} alt="Avalanche logo" title="Avalanche" />;
    case 'bitcoin':
      if (fmt === 'mono') {
        return <img src={btc_bw} alt="Bitcoin logo" title="Bitcoin" />;
      }
      return <img src={btc} alt="Bitcoin logo" title="Bitcoin" />;
    case 'polymesh':
      if (fmt === 'mono') {
        return <img src={polyx_bw} alt="Polymesh logo" title="Polymesh" />;
      }
      return <img src={polyx} alt="Polymesh logo" title="Polymesh" />;
    case 'stellar':
      if (fmt === 'mono') {
        return <img src={xlm_bw} alt="Stellar logo" title="Stellar" />;
      }
      return <img src={xlm} alt="Stellar logo" title="Stellar" />;
    default:
      return <div></div>;
  }
}

export function validateAddress(addr: string, network: string): boolean {
  switch (network) {
    case 'bitcoin':
      return validate(addr);
    case 'ethereum':
    case 'avalanche':
      return isAddress(addr);
    case 'stellar':
      const regex = /^G[A-Z2-7]{55}$/;
      return regex.test(addr);
    case 'polymesh':
      try {
        return Boolean(base58_to_binary(addr));
      } catch (e) {
        return false;
      }
    default:
      return false;
  }
}

// TODO: this function is serving more than one purpose in an unintuitive way
export function formatNetwork(s: string): string {
  switch (s.toLowerCase()) {
    case 'btc':
      return 'bitcoin';
    case 'eth':
      return 'ethereum';
    case 'polyx':
      return 'polymesh';
    case 'xlm':
      return 'stellar';
    case 'avax':
      return 'avalanche';
    case 'bitcoin':
      return 'BTC';
    case 'ethereum':
      return 'ETH';
    case 'polymesh':
      return 'POLYX';
    case 'stellar':
      return 'XLM';
    case 'avalanche':
      return 'AVAX';
    default:
      return '';
  }
}

export function conversionFactor(asset: string, fractionDigits: number): number {
  switch (asset.toLowerCase()) {
    case 'eth':
    case 'avax':
      return 1e-18;
    case 'btc':
    case 'ltc':
      return 1e-8;
    case 'polyx':
      return 1e-6;
    case 'xlm':
      return 1e-7;
    default:
      if (fractionDigits) {
        return BigNumber(10).pow(-fractionDigits).toNumber();
      }
      return 1;
  }
}

export function nativeTokenByNetwork(network: string): string {
  switch (network) {
    case 'bitcoin':
      return 'BTC';
    case 'ethereum':
      return 'ETH';
    case 'avalanche':
      return 'AVAX';
    case 'polymesh':
      return 'POLYX';
    case 'stellar':
      return 'XLM';
    default:
      return '';
  }
}

/**
 * @description - Format an integer amount to a decimal amount in base units; e.g. 5000 Satoshi to 0.00005 BTC.
 * @param amount - The amount, given as an integer
 * @param asset - The asset, e.g. 'btc' or 'eth'
 * @param fractionDigits - The number of digits expected after the decimal. Set to 0 for full precision (default).
 */
export function formatIntegerAmount(
  amount: string | number,
  asset: string,
  fractionDigits: number = 0,
): string {
  const res = BigNumber(amount).times(conversionFactor(asset, fractionDigits));

  if (fractionDigits <= 0) {
    return res.toString();
  }
  return res.toFormat(fractionDigits);
}
